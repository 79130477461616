import React from 'react'
import ExperienceData from './ExperienceData'
import './ExperienceStyle.css';
const Experience = () => {
  return (
    <>
    <section className="experience">
    <div className="section-spacing experienece-data">

    {ExperienceData.map((eData)=>{
        return(
            <div className="experienece-inner-data">
        <span className='eicon'>{<eData.eicon style={{fontSize:"40px"}}/>}</span>
        <h6 className='e-subtitle'>{eData.subtitle}</h6>
        <h4 className='e-title'>{eData.title}</h4>

        </div>
        )
        

    })}

    </div>

    

        
    </section>

    </>
  )
}

export default Experience