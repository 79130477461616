// import React,{useState} from 'react'
// import './ContcatFromStyle.css';


// const ContactForm = () => {

//     const[data,setData]=useState({
//         fname:'',
//         lname:'',
//         email:''

//     });

// const formData=(e)=>{
//     // const getVal=(e.target.value);
//     // setData(...getVal,getVal);
//     const name=(e.target.name);
//     const value=(e.target.value);
//     console.log(name,value);
//     setData({...data,[name]:value});
//     // setData((pre)=>{
//     //     return{
//     //         ...pre,[name]:value
//     //     }
//     // })

// }


// const submitform=(e)=>{
//     e.preventDefault();
//     const newData={...data}
//     alert(newData);

// }


//   return (
//     <section className="contact-form-spacing">
//         <div className="section-spacing">
//             <div className="eccounting-contcat-form">
//                 <form action="" onSubmit={submitform}>
//                     <label htmlFor="fname">First Name:</label>
//                     <input type="text" placeholder='Enter your fname' name='fname' value={data.fname} onChange={formData} />
//                     <label htmlFor="lname" >Last Name:</label>
//                     <input type="text" placeholder='Enter your lname' name='lname' value={data.lname} onChange={formData}/>
//                     <label htmlFor="fname">Email:</label>
//                     <input type="email" placeholder='Enter your Email' name='email' value={data.email} onChange={formData}/>
//                     <button className="submit-form">Submit</button>
//                 </form>
//             </div>
//         </div>
//     </section>
//   )
// }

// export default ContactForm;


// =============== Short Circuit Evaluation ==============

// import React,{useState} from 'react'

// const ContactForm = () => {
//     const[show,setShowData]=useState(false);

//   return (
//     <>

//     {show?<h1>Hlo to the Future!</h1>:null}
//     <button onClick={()=>setShowData(!show)}>Show</button>
//     {/* <button onClick={()=>setShowData(false)}>Hide</button> */}

//     </>
//   )
// }

// export default ContactForm

///////////  && || //////////

import React,{useState} from 'react'
import './ContcatFromStyle.css';

const ContactForm = () => {
    const [inputData, setInputData] = useState({
        name:'',
        email:'',
        message:''
    });
    const data=(e)=>{
        const {name,value}=e.target;
        setInputData({...inputData,[name]:value})

    }
    const submitData=(e)=>{
        e.preventDefault();
        const{name,email,message}=inputData;
        if(!name||!email||!message){
            alert('Please Enter Details');
        }
        console.log(name,email,message);
        alert('Data Submited');
        setInputData({name:'',email:'',message:''});

    }
    
  return (
    <>
        <div className="content-spacing content-spacing-tb user-rigiter">
            <input type="text" placeholder='Enter Your Name' required name='name' value={inputData.name} onChange={data}/>
            <input type="email" placeholder='Enter Your Email' required name='email' value={inputData.email} onChange={data} />
            <input type='text' placeholder='Enter Your Message' required  name='message' value={inputData.message} onChange={data}/>
            <button type='submit' onClick={submitData}>Submit</button>
        </div>
    </>
  )
}

export default ContactForm