import React from 'react'
import "./BusinessHelpStyle.css"
import {Link} from "react-router-dom";
import {IoMdTrophy} from "react-icons/io";
import Businessdata from './Businessdata';
const BusinessHelp = (props) => {
  return (
    <>
        <section className="section-spacing">
            <h2>How can we help you?</h2>

            <div className="business-help">

            {Businessdata.map(businessVal=>{

                return(
                    <div className="business-content">

                    <div className="business-icon">
                    <businessVal.icon className="b-icon"/>
                    </div>
                    <div className="business-inner-content">
                    <Link to="#"><h5>{businessVal.title}</h5></Link>
                    </div>

                </div>)})

            }


            </div>
        </section>
    </>
  )
}

export default BusinessHelp