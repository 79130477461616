import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import ProjectsData from './ProjectData';
import './ProjectStyle.css';
import HeadingTitle from '../headingTitle/HeadingTitle';


const Projects = () => {

    const[project,setProject]=useState(ProjectsData);

    const filterImages=(contentFilter)=>{
        const updateFilter=ProjectsData.filter((contentFilterVal)=>{
            return contentFilterVal.subTitle===contentFilter;
        })
        setProject(updateFilter);

    }
 
    const imgSize=()=>{
        
    }

  return (
    <>
    <HeadingTitle heading="Projects"></HeadingTitle>

    <section className="gallery">
        <div className="project-gallery">
            <div className="project-gallery-content">
            <Link to="#" className='project-btn' onClick={()=>setProject(ProjectsData)}>All</Link>
            <Link to="#" className='project-btn' onClick={()=>filterImages('Item Design')}>Item Design</Link>
            <Link to="#" className='project-btn' onClick={()=>filterImages('Web Design')}>Web Design</Link>
            <Link to="#" className='project-btn' onClick={()=>filterImages('Logo Design')}>Logo Design</Link>
            </div>
        </div>
    </section>

    <section className="filter-project section-spacing">

       { project.map((projectVal)=>{

        return(
            <div className="filter-project-content" key={projectVal.id}>
            <div className="galler-img">
            <img src={projectVal.img} alt="best Data" onClick={imgSize}/>
            </div>
            <div className="gallery-content">
            <h5>{projectVal.title}</h5>
            <p>{projectVal.subTitle}</p>
            </div>

            </div>

            
        )



        })}

    </section>


    </>
  )
}

export default Projects