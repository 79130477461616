import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <>
        <div className="footer">
            <p>© 2020 Copyright: All Rights are Reserved.</p>
        </div>
    </>
  )
}

export default Footer