import React from 'react'

const HeadingTitle = (props) => {
  return (
    <>
    <section className="section-spacing">
      <h2>{props.heading}</h2>
      </section>
    </>
  )
}

export default HeadingTitle