import {IoMdTrophy,IoIosBrowsers} from "react-icons/io";
const Businessdata=[
    {
        id:'1',
        icon:IoMdTrophy,
        title:'Helping to start a business'
    },

    {
        id:'2',
        icon:IoMdTrophy,
        title:'Helping to start a business'
    },

    {
        id:'3',
        icon:IoIosBrowsers,
        title:'Helping to start a business'
    },

    {
        id:'4',
        icon:IoMdTrophy,
        title:'Helping to start a business'
    },

    {
        id:'5',
        icon:IoIosBrowsers,
        title:'Helping to start a business'
    },
    {
        id:'6',
        icon:IoMdTrophy,
        title:'Helping to start a business'
    }

];

export default Businessdata;