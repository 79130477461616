const ProjectsData=[
    {
        id:1,
        img:"images/Modern arts museum.png",
        title:"Modern arts museum",
        subTitle:"Web Design"

    },
    {
        id:2,
        img:"images/Minimalistic workspace.png",
        title:"Minimalistic workspace",
        subTitle:"Web Design"

    },
    {
        id:3,
        img:"images/Red space.png",
        title:"Red space",
        subTitle:"Web Design"

    },
    {
        id:4,
        img:"images/Illumination.png",
        title:"Illumination",
        subTitle:"Item Design"

    },
    {
        id:5,
        img:"images/Neon waves.png",
        title:"Neon waves",
        subTitle:"Web Design"

    },
    {
        id:6,
        img:"images/The future is now.png",
        title:"The future is now",
        subTitle:"Logo Design"

    },


]
export default ProjectsData;