import React from 'react'
import ContactPage from '../components/ContactPage/ContactPage';
import Footer from '../components/Footer/Footer';

const Contact = () => {
  return (
    <>
    <ContactPage></ContactPage>
    <Footer/>

    </>
  )
}

export default Contact;