import { IoIosGlobe,IoMdContacts,IoIosAlbums } from "react-icons/io";
const ExperienceData=[
    {
        eicon:IoIosAlbums,
        subtitle:"Our experience",
        title:"20+ Years"

    },
    {
        eicon:IoIosGlobe,
        subtitle:"We operate in",
        title:"15 Countries"

    },
    {
        eicon:IoMdContacts,
        subtitle:"We currently serve to",
        title:"200 000+ clients"

    },

];
export default ExperienceData;