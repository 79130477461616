import React from 'react'
import AboutComponent from '../components/AboutComponent/AboutComponent'
import Footer from '../components/Footer/Footer'

const About = () => {
  return (
    <>
    <AboutComponent></AboutComponent>
    <Footer/>
   
    </>

  )
}

export default About