import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavbarStyle.css";
import { IoIosMenu } from "react-icons/io";

const Navbar = () => {
//   const [menu, setMenu] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  return (
    <>
      {/* <section className="nav-bar section-spacing">
        <div className="nav-logo">
          <img src="images/logo.svg" alt="logo" />
        </div>
        <div className="nav-menu">
          <ul>
            <NavLink to="/">
              <li>Home</li>
            </NavLink>
            <NavLink to="/about">
              <li>About</li>
            </NavLink>
            <NavLink to="/contact">
              <li>Contact</li>
            </NavLink>
            <NavLink to="faqs">
              <li>Faq's</li>
            </NavLink>
          </ul>
        </div>
      </section>

      <section className="mobile-menu">
        <div className="mobile-menu">
          <IoIosMenu
            className="m-icon"
            onClick={() => {
              setMenu(!menu);
            }}
          />
        </div>
      </section> */}




      {/* ============= Check Navbar ====== */}



 

    <nav className="navbar">
      <div className="container">
        <div className="logo">
        <img src="images/logo.svg" alt="logo" />
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <IoIosMenu />
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            
          </ul>
        </div>
      </div>
    </nav>
  


    </>
  );
};

export default Navbar;
