import React from 'react'
import { Link } from 'react-router-dom';
import { IoIosArrowRoundForward } from "react-icons/io";
import "./Hero.css";

const Hero = () => {
  return (
    <>

    <section className="section-spacing hero">
        <div className="side-content">

        <h5>Welcome to Seven Accounting</h5>
        <h1>Virtual Assistance & Accounting Services</h1>
        <p>We provide a full spectrum of traditional tax and accounting services. Let's make your business more successful - talk to our accounting experts today!</p>
        <Link to="#" className="btn-primary">Schedule free consultation</Link><span>
        <Link to="#" className="btn-secondary">Discover our services<IoIosArrowRoundForward style={{fontSize:"23px",marginLeft:"3px"}}/></Link>
        </span>

        </div>

        <div className="hero-img">
            <img src="images/Hand Wraps stretchy02.png" alt="Hero Image" />
        </div>
    </section>

    </>
  )
}

export default Hero;