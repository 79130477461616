import React from 'react'
import HeadingTitle from '../headingTitle/HeadingTitle'
import CommonAccounting from './CommonAccounting'

const SevenAccounting = () => {
  return (
    <>
      <HeadingTitle heading="Why Seven Accounting?"></HeadingTitle>
      <CommonAccounting
      img="images/Hand Wraps stretchy04.png"
      title="One-on-one expert support"
      desc="Phasellus aliquam vehicula mauris et elementum. Sed nisl risus, maximus non tortor vel, vestibulum condimentum ipsum. Ut convallis molestie imperdiet. Sed scelerisque sodales elementum. Morbi at leo tortor. Etiam quis gravida nunc, id euismod risus. Morbi id viverra dui."
      ></CommonAccounting>
    
      
    </>
  )
}

export default SevenAccounting