import React from 'react';
import CommonAccountingSecond from './CommonAccountingSecond';

const FinancialReporting = () => {
  return (
    <>
      <CommonAccountingSecond
      img="images/Hand Wraps stretchy05.png"
      title="Powerful financial reporting"
      desc="Suspendisse imperdiet quis est id facilisis. Duis tempus odio id suscipit fermentum. Vivamus molestie, eros eu pulvinar laoreet, ante nisl convallis arcu, nec sodales enim mi ac tellus. Morbi laoreet libero ac malesuada sodales. Aenean quis leo vitae libero sollicitudin iaculis. Pellentesque hendrerit!"
      ></CommonAccountingSecond>
    </>
  )
}

export default FinancialReporting