import React from 'react'
import HeadingTitle from '../headingTitle/HeadingTitle'
import Navbar from '../Navbar/Navbar'
import Practice from '../Pratice/Practice'
import CommonAccountingSecond from '../SevenAccounting/CommonAccountingSecond'
import Experience from './Experience/Experience'

const About = () => {
  return (
    <>
    <Navbar></Navbar>
    <HeadingTitle heading="About us"></HeadingTitle>

      <CommonAccountingSecond
      img="images/Hand Wraps stretchy05.png"
      title="Reliable & affordable accounting services for your business"
      desc="Suspendisse imperdiet quis est id facilisis. Duis tempus odio id suscipit fermentum. Vivamus molestie, eros eu pulvinar laoreet, ante nisl convallis arcu, nec sodales enim mi ac tellus. Morbi laoreet libero ac malesuada sodales. Aenean quis leo vitae libero sollicitudin iaculis. Pellentesque hendrerit!"
      ></CommonAccountingSecond>

      <Experience></Experience>
    </>

  )
}

export default About;