import React from "react";
import BusinessHelp from "../components/businessHelp/BusinessHelp";
import Footer from "../components/Footer/Footer";
import Hero from "../components/Hero/Hero";
import Navbar from "../components/Navbar/Navbar";
import Projects from "../components/Projects/Projects";
import FinancialReporting from "../components/SevenAccounting/FinancialReporting";
import SevenAccounting from "../components/SevenAccounting/SevenAccounting";

const Home = () => {
  return (
    <>

      <Navbar></Navbar>
      <Hero></Hero>
      <BusinessHelp></BusinessHelp>
      <SevenAccounting></SevenAccounting>
      <FinancialReporting></FinancialReporting>
      <Projects></Projects>
      <Footer/>
    </>
  );
};

export default Home;
