import React from "react";
import "./SevenAccounting.css";
const CommonAccountingSecond = (props) => {
  return (
    <>
        <div className="seven-accounting-content section-spacing">
          <div className="seven-accounting-inner-content">
            <h3>{props.title}</h3>
            <p>{props.desc}</p>
          </div>
          <div className="seven-accounting-img">
            <img src={props.img} alt="accounting" />
          </div>
        </div>
    </>
  );
};

export default CommonAccountingSecond;
